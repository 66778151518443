

import React, { useState, useEffect,useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Tarjetas.css'; // Asume que este es tu archivo CSS con los estilos de la tarjeta

function TarjetaIndividual({ unidad }) {
  const [volteada, setVolteada] = useState(false);

  const toggleVolteada = () => setVolteada(!volteada);
  


  return (
    <div>
          
          
            <div className="tarjeta-contenedor" onClick={toggleVolteada}>
              <div className={`tarjeta ${volteada ? 'volteada' : ''}`}>
                <div className="frente">
                <div className="esquina">Frente</div>
                  <h5>{unidad.frente}</h5>
                </div>
                <div className="dorso">
                <div className="esquina">Dorso</div>
                  <h5 className="mb-20">{unidad.dorso}</h5>
                  
                  <h6>({unidad.ejemplo})</h6>
                </div>
              </div>
            </div>
    </div>
  );
}

function Tarjetas() {
    const [Aprender, setAprender] = useState([]);
    const [indiceActual, setIndiceActual] = useState(0); // Nuevo estado para el índice de la tarjeta actual
    const [error, setError] = useState(false);
    const [cargando, setCargando] = useState(true);
    let { id } = useParams();
    let navigate = useNavigate();

    const navigateToinsertAprender = () => navigate(`/tarjetas-agregar/${id}`);
  
  // Memoriza la función siguienteTarjeta para que no cambie si las dependencias no han cambiado
  const siguienteTarjeta = useCallback(() => {
    setIndiceActual((prevIndice) => (prevIndice + 1) % Aprender.length);
  }, [Aprender.length]); // Dependencias de la función

  // Memoriza la función anteriorTarjeta por la misma razón
  const anteriorTarjeta = useCallback(() => {
    setIndiceActual((prevIndice) => (prevIndice - 1 + Aprender.length) % Aprender.length);
  }, [Aprender.length]);



useEffect(() => {
  const manejarTeclado = (evento) => {
      switch (evento.key) {
          case 'ArrowLeft':
              anteriorTarjeta();
              break;
          case 'ArrowRight':
              siguienteTarjeta();
              break;
          default:
              break;
      }
  };

  window.addEventListener('keydown', manejarTeclado);

  return () => {
      window.removeEventListener('keydown', manejarTeclado);
  };
}, [anteriorTarjeta, siguienteTarjeta]);


    useEffect(() => {
        const obtenerAprender = async () => {

          setCargando(true); // Inicia la carga

            try {
                const response = await axios.get(`https://aprendizaje.chetito.shop/api/obtener_tarjetas.php?leccion_id=${id}`);
                if (response.data.success && response.data.data.length > 0) {
                    setAprender(response.data.data);
                    setError(false);
                }
            } catch (error) {
                console.error('Error al obtener las Aprender:', error);
                setError(true);
            }finally {
              setCargando(false); // Finaliza la carga
          }
        };

        obtenerAprender();
    }, [id]);

    if (error) {
        return <div className="container mt-5">No se encontraron Aprender para este curso o no se puede acceder a ellas en este momento.</div>;
    }


    const handleBack = () => {
      navigate(-1); // Navega a la página anterior
  };

    return (
      <div className="container mt-5">
        <button type="button" className="btn btn-secondary" onClick={handleBack}>Regresar</button>
          <h2>Aprender con tarjetas</h2>
          <button onClick={navigateToinsertAprender} className="btn btn-primary">Agregar Tarjeta</button>

          {cargando ? (
                    <div style={{ textAlign: 'center' }}>
                        <img src="/cargando.png" className="cargando" alt="Cargando" style={{ display: 'block', margin: 'auto' }} />
                        <p>Procesando, espere un momento...</p>
                    </div>
                ) : 
          Aprender.length > 0 && (
              <>
                <div className="tarjetas-slider">
                    <TarjetaIndividual unidad={Aprender[indiceActual]} />

                </div>
                <br />
                <div className="d-flex justify-content-center">

              <button className="btn btn-primary me-2" onClick={anteriorTarjeta}>Anterior</button>
              <button className="btn btn-secondary" onClick={siguienteTarjeta}>Siguiente</button>

          </div>

              </>
          )}
          {Aprender.length === 0 && <div>No se encontraron Aprender para este curso.</div>}
      </div>
    );
}

export default Tarjetas;
