// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import AdminCursos from './components/AdminCursos';
import CursoForm from './components/CursoForm';

import ListaCursos from './components/ListaCursos';
import Unidades from './components/Unidades';
import InsertarUnidad from './components/InsertarUnidad';

import Lecciones from './components/Lecciones';
import InsertarLeccion from './components/InsertarLeccion';

import Aprender from './components/Aprender';
import InsertarTarjetas from './components/InsertarTarjetas';

import Tarjetas from './components/Tarjetas';
import TarjetasEvaluar from './components/TarjetasEvaluar';

import AprendizajeTemporal from './components/AprendizajeTemporal';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AdminCursos />} />

        <Route path='/aprn-tmp' element={<AprendizajeTemporal/>} />

        <Route path="/curso-form" element={<CursoForm />} />
        <Route path="/listar-cursos" element={<ListaCursos />} />

        <Route path="/unidades/:id" element={<Unidades />} /> 
        <Route path="/insertar-unidades/:id" element={<InsertarUnidad />} /> 

        <Route path="/lecciones/:id" element={<Lecciones />} /> 
        <Route path="/insertar-Lecciones/:id" element={<InsertarLeccion />} /> 

        <Route path="/aprender/:id" element={<Aprender />} /> 


        <Route path="/tarjetas-agregar/:id" element={<InsertarTarjetas />} /> 
        <Route path="/tarjetas/:id" element={<Tarjetas />} /> 
        <Route path="/tarjetas-evaluar/:id" element={<TarjetasEvaluar />} /> 

      </Routes>
    </Router>
  );
}

export default App;
