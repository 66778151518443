// Lecciones.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';


function Lecciones() {
    let { id } = useParams(); // Extrae el ID del parámetro de la URL
    let navigate = useNavigate();

    const navigateToinsertLecciones = () => {
        navigate(`/insertar-Lecciones/${id}`);
      };

      const handleBack = () => {
        navigate(-1); // Navega a la página anterior
    };

    const [Lecciones, setLecciones] = useState([]);
    const [info, setinfo] = useState([]);
    const [error, setError] = useState(false); // Nuevo estado para manejar errores
    const [cargando, setCargando] = useState(true);



    useEffect(() => {
        const obtenerLecciones = async () => {
          setCargando(true); // Inicia la carga
          try {


            const [response, infoResponse] = await Promise.all([
              axios.get(`https://aprendizaje.chetito.shop/api/obtener_lecciones.php?unidad_id=${id}`),
              axios.get(`https://aprendizaje.chetito.shop/api/obtener_lecciones_one.php?id=${id}`)
          ]);

            if (response.data.success && response.data.data.length > 0) {

            setLecciones(response.data.data);
            setError(false);
                } 


                if (infoResponse.data.success && infoResponse.data.cursos.length > 0) {
                  setinfo(infoResponse.data.cursos);
              }

          } catch (error) {
            console.error('Error al obtener las Lecciones:', error);
          }finally {
            setCargando(false); 
        }
        };
    
        obtenerLecciones();
      }, [id]); 
    


      if (error) {
        // Mensaje cuando hay un error o no se encuentran datos
        return <div className="container mt-5">No se encontraron Lecciones para este curso o no se puede acceder a ellas en este momento.</div>;
      }

    return (
        <div className="m-4" >
            <div className="admin-cursos">

            {Object.keys(info).map((key) => (
    <div key={key}>
        <h5>Lecciones de: {info[key].titulo}</h5>
        <p>({info[key].descripcion})</p>
    </div>
))}


            <button type="button" className="btn btn-secondary me-2" onClick={handleBack}>Regresar</button>
            <button onClick={navigateToinsertLecciones} className="btn btn-primary">Agregar Lección</button>
            <hr></hr>
            </div>
            <div className="container mt-5">
      <h2>Lecciones</h2>
      {cargando ? (
                    <div style={{ textAlign: 'center' }}>
                        <img src="/cargando.png" className="cargando" alt="Cargando" style={{ display: 'block', margin: 'auto' }} />
                        <p>Procesando, espere un momento...</p>
                    </div>
                ) : 
      Lecciones.length > 0 ? (
        <ul className="list-group">
          {Lecciones.map((unidad) => (
            <li key={unidad.id} className="list-group-item">
              <h5>{unidad.titulo}</h5>
              <p>{unidad.descripcion}</p>
              <Link to={`/aprender/${unidad.id}`} className="btn btn-primary">Aprender</Link>
            </li>
          ))}
        </ul>
      ) : (
        <div>No se encontraron Lecciones para este curso.</div>
      )
      }


    </div>
        </div>
    );
}

export default Lecciones;
