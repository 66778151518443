

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Tarjetas.css';

function TarjetasEvaluar() {
    const [Aprender, setAprender] = useState([]);
    const [indiceActual, setIndiceActual] = useState(0); // Añade un estado para el índice actual
    const [error, setError] = useState(false);
    const [correctas, setCorrectas] = useState(0); // Añade un estado para el conteo de respuestas correctas
    const [incorrectas, setIncorrectas] = useState(0); // Añade un estado para el conteo de respuestas incorrectas
    const [mostrarCorrecto, setMostrarCorrecto] = useState(false); // Para mostrar mensaje de respuesta correcta
    const inputRef = useRef(null); // Referencia para el campo de entrada
    let { id } = useParams();
    let navigate = useNavigate();
    const [cargando, setCargando] = useState(true);


    useEffect(() => {
        const obtenerAprender = async () => {
            setCargando(true); // Inicia la carga
            try {
                const response = await axios.get(`https://aprendizaje.chetito.shop/api/obtener_tarjetas.php?leccion_id=${id}`);
                if (response.data.success && response.data.data.length > 0) {
                    const dataConCampoAleatorio = response.data.data.map((tarjeta) => {
                        const campos = ['frente', 'dorso', 'ejemplo'];
                        const campoAleatorio = campos.splice(Math.floor(Math.random() * campos.length), 1)[0];
                        const campoMostrar = campos[Math.floor(Math.random() * campos.length)];
                        return {
                            ...tarjeta,
                            campoAleatorio,
                            campoMostrar,
                            respuestaUsuario: '',
                            correcto: null,
                        };
                    });
                    setAprender(dataConCampoAleatorio);
                    setError(false);
                }
            } catch (error) {
                console.error('Error al obtener las tarjetas:', error);
                setError(true);
            }finally {
                setCargando(false); // Finaliza la carga
            }
        };

        obtenerAprender();
    }, [id]);

    useEffect(() => {
        // Asegura que el input tenga siempre el foco
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [indiceActual, Aprender]); // Dependencias para re-focar cuando cambie la tarjeta o el arreglo de tarjetas


    const handleSubmit = (valorCorrecto) => {
        const esCorrecto = Aprender[indiceActual].respuestaUsuario.trim().toLowerCase() === valorCorrecto.trim().toLowerCase();
        if (esCorrecto) {
            setCorrectas(correctas + 1);
            setMostrarCorrecto(true);
            setTimeout(() => {
                setIndiceActual(prevIndice => prevIndice + 1 < Aprender.length ? prevIndice + 1 : 0);
                setAprender((prevAprender) =>
                prevAprender.map((tarjeta, idx) =>
                    idx === indiceActual ? { ...tarjeta, respuestaUsuario: '', correcto: null } : { ...tarjeta, respuestaUsuario: '', correcto: null }
                )
            );
                setMostrarCorrecto(false); // Oculta el mensaje de éxito para la siguiente pregunta
            }, 1000); // Espera 2 segundos para mostrar el mensaje de éxito antes de pasar a la siguiente tarjeta
        } else {
            setIncorrectas(incorrectas + 1);
            setAprender(prevAprender =>
                prevAprender.map((tarjeta, idx) =>
                    idx === indiceActual ? { ...tarjeta, correcto: false } : tarjeta
                )
            );
        }
    };
    
    const handleKeyDown = (e, valorCorrecto) => {
        if (e.key === 'Enter') {
            handleSubmit(valorCorrecto);
        }
    };
    const handleChange = (valor) => {
        setAprender((prevAprender) =>
            prevAprender.map((tarjeta, idx) =>
                idx === indiceActual ? { ...tarjeta, respuestaUsuario: valor } : tarjeta
            )
        );
    };

    if (error) {
        return <div className="container mt-5">No se encontraron tarjetas para este curso o no se puede acceder a ellas en este momento.</div>;
    }

    const handleBack = () => {
        navigate(-1);
    };

    const tarjetaActual = Aprender[indiceActual];

    return (
        <div className="container mt-5">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Regresar</button>
            <h2>Evaluar con TarjetasEvaluar</h2>
            
            {cargando ? (
                    <div style={{ textAlign: 'center' }}>
                        <img src="/cargando.png" className="cargando" alt="Cargando" style={{ display: 'block', margin: 'auto' }} />
                        <p>Procesando, espere un momento...</p>
                    </div>
                ) : 
            Aprender.length > 0 && (
                <>
                    {mostrarCorrecto ? (
                        // Muestra un mensaje temporal de éxito cuando la respuesta es correcta
                        <div className="alert alert-success" role="alert">
                            ¡Respuesta Correcta!
                        </div>
                    ) : (
                        <div key={tarjetaActual.id}>
                            <p>{tarjetaActual.campoMostrar}: {tarjetaActual[tarjetaActual.campoMostrar]}</p>
                            <p>Escribe la respuesta para el <b>{tarjetaActual.campoAleatorio}</b> de la tarjeta</p>
                            <input
                                ref={inputRef} // Establece la referencia al input
                                type="text"
                                value={tarjetaActual.respuestaUsuario}
                                onChange={(e) => handleChange(e.target.value)}
                                onKeyDown={(e) => handleKeyDown(e, tarjetaActual[tarjetaActual.campoAleatorio])} // Manejador para detectar Enter
                                placeholder="Tu respuesta aquí"
                                className="form-control mb-2" // Agrega clases para estilizar el input
                            />
                            
                            <button className="btn btn-primary" onClick={() => handleSubmit(tarjetaActual[tarjetaActual.campoAleatorio])}>Enviar</button>
                            {tarjetaActual.correcto === false && (
                                <div className="alert alert-danger mt-2" role="alert">
                                    Incorrecto! La respuesta correcta era: {tarjetaActual[tarjetaActual.campoAleatorio]}
                                </div>
                            )}
                        </div>
                    )}
                    {/* Muestra el conteo de respuestas correctas e incorrectas */}
                    <div className="mt-3">
                        <p>Respuestas correctas: {correctas}</p>
                        <p>Respuestas incorrectas: {incorrectas}</p>
                    </div>
                </>
            )}
            {Aprender.length === 0 && <div>No se encontraron tarjetas para este curso.</div>}
        </div>
    );
    
}

export default TarjetasEvaluar;
