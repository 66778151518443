import React, { useState, useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function AprendizajeTemporal() {
    const [frase1, setFrase1] = useState('');
    const [frase2, setFrase2] = useState('');
    const [palabra1, setPalabra1] = useState('');
    const [palabra2, setPalabra2] = useState('');
    const [habilitado, setHabilitado] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [correctas, setCorrectas] = useState(0);
    const [incorrectas, setIncorrectas] = useState(0);
    const [oculto, setOculto] = useState(null);
    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);

    useEffect(() => {
        if (habilitado) {
            inputRef1.current.focus();
        }
    }, [habilitado, oculto]);

    const manejarAprender = () => {
        setHabilitado(true);
        setCorrectas(0);
        setIncorrectas(0);
        setOculto(null);
        setMensaje('');
    };

    const verificarPalabras = (event) => {
        event.preventDefault();
        const esCorrecto1 = palabra1.toLowerCase() === frase1.toLowerCase();
        const esCorrecto2 = palabra2.toLowerCase() === frase2.toLowerCase();
        if (esCorrecto1 && esCorrecto2) {
            setMensaje('¡Correcto! Ambas palabras son exactas.');
            setCorrectas(correctas + 1);
            if ((correctas + 1) >= 3) {
                setOculto(Math.floor(Math.random() * 2) + 1); // Elegir aleatoriamente qué input de aprendizaje ocultar
                console.log(oculto)
            }
        } else {
            setMensaje('Incorrecto. Inténtalo de nuevo.');
            setIncorrectas(incorrectas + 1);
        }
        setPalabra1('');
        setPalabra2('');
        
        inputRef1.current.focus();
        
    };

    const resetear = () => {
        setFrase1('');
        setFrase2('');
        setPalabra1('');
        setPalabra2('');
        setHabilitado(false);
        setMensaje('');
        setCorrectas(0);
        setIncorrectas(0);
        setOculto(null);
    };

    return (
        <div className="container mt-4">
            <div className="row">
                <h1>Aprendizaje Temporal</h1>
                <div className="col-md-6">
                    <label htmlFor="fraseInput1" className="form-label">Palabra uno</label>
                    <input 
    type="text" 
    className="form-control" 
    id="fraseInput1"
    value={oculto === 1 ? "********" : frase1} // Mostrar asteriscos cuando oculto sea 1
    onChange={(e) => setFrase1(e.target.value)}
    disabled={habilitado }
/>

                </div>
                <div className="col-md-6">
                    <label htmlFor="fraseInput2" className="form-label">Palabra dos</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="fraseInput2"
                        value={oculto === 2 ? "********" : frase2} 
                        onChange={(e) => setFrase2(e.target.value)}
                        disabled={habilitado}
                    />
                </div>
            </div>
            <button className="btn btn-primary mt-3" onClick={manejarAprender} disabled={habilitado}>
                Aprender
            </button>
            {habilitado && (
                <form onSubmit={verificarPalabras} className="mt-3">
                    <div className="row">
                        <div className="col-md-6">
                            <input 
                                ref={inputRef1}
                                type="text" 
                                className="form-control" 
                                placeholder="Escribe la palabra uno"
                                value={palabra1}
                                onChange={(e) => setPalabra1(e.target.value)}
                             
                            />
                        </div>
                        <div className="col-md-6">
                            <input 
                                ref={inputRef2}
                                type="text" 
                                className="form-control" 
                                placeholder="Escribe la palabra dos"
                                value={palabra2}
                                onChange={(e) => setPalabra2(e.target.value)}
                               
                            />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-success mt-3">Verificar</button>
                    <div className={`alert ${mensaje.includes('Correcto') ? 'alert-info' : 'alert-danger'} mt-3`}>
                        {mensaje}
                    </div>
                    <div className="mt-3">
                        Correctas: {correctas}, Incorrectas: {incorrectas}
                    </div>
                </form>
            )}
            <button className="btn btn-secondary mt-3" onClick={resetear}>
                Resetear Todo
            </button>
        </div>
    );
}

export default AprendizajeTemporal;
