// AdminCursos.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ListaCursos from './ListaCursos';


function AdminCursos() {
  let navigate = useNavigate();

  const navigateToCursoForm = () => {
    navigate('/curso-form');
  };

  
  const navigateToAprntmp = () => {
    navigate('/aprn-tmp');
  };




  return (
    <div className="m-4"> {/* Cambia aquí por m-3, m-4, m-5, etc., según el margen deseado */}
      <h2>Administración de Aprendizaje</h2>
      <button onClick={navigateToCursoForm} className="btn btn-primary m-2">Agregar Aprendizaje</button>
      
      <button onClick={navigateToAprntmp} className="btn btn-primary">Aprendizaje Temporal</button>
      <hr />
      <ListaCursos />
    </div>
);



}

export default AdminCursos;
