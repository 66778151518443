// Unidades.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';


function Unidades() {
    let { id } = useParams(); // Extrae el ID del parámetro de la URL
    let navigate = useNavigate();

    const navigateToinsertUnidades = () => {
        navigate(`/insertar-unidades/${id}`);
      };

      const handleBack = () => {
        navigate(-1); // Navega a la página anterior
    };

    const [unidades, setUnidades] = useState([]);
    const [info, setinfo] = useState([]);
    const [error, setError] = useState(false); // Nuevo estado para manejar errores
    const [cargando, setCargando] = useState(true);



    useEffect(() => {
        const fetchData = async () => {
            try {
                setCargando(true); // Inicia la carga
    
                const [unidadesResponse, infoResponse] = await Promise.all([
                    axios.get(`https://aprendizaje.chetito.shop/api/obtener_unidades.php?curso_id=${id}`),
                    axios.get(`https://aprendizaje.chetito.shop/api/obtener_cursos_one.php?id=${id}`)
                ]);
    
                if (unidadesResponse.data.success && unidadesResponse.data.data.length > 0) {
                    setUnidades(unidadesResponse.data.data);

                   
                }
    
                if (infoResponse.data.success && infoResponse.data.cursos.length > 0) {
                    setinfo(infoResponse.data.cursos);
                }
    
                setError(false); // Restablecer el estado de error en caso de éxito
            } catch (error) {
                console.error('Error:', error);
                setError(true); // Establecer el estado de error
            } finally {
                setCargando(false); // Finaliza la carga
            }
        };
    
        fetchData();
    }, [id]); // El efecto se ejecuta cuando cambia el valor de `id`
    

      if (error) {
        // Mensaje cuando hay un error o no se encuentran datos
        return <div className="container mt-5">No se encontraron unidades para este curso o no se puede acceder a ellas en este momento.</div>;
      }

      return (
        <div className="m-4" >
            <div>
            {Object.keys(info).map((key) => (
    <div key={key}>
        <h5>Aprendizaje de: {info[key].nombre}</h5>
        <p>({info[key].descripcion})</p>
    </div>
))}
                <button type="button" className="btn btn-secondary me-2" onClick={handleBack}>Regresar</button>
                <button onClick={navigateToinsertUnidades} className="btn btn-primary">Agregar Curso</button>
                <hr></hr>
            </div>
      
            <div className="container mt-5">
                <h2>Cursos:</h2>
                {cargando ? (
                    <div style={{ textAlign: 'center' }}>
                        <img src="/cargando.png" className="cargando" alt="Cargando" style={{ display: 'block', margin: 'auto' }} />
                        <p>Procesando, espere un momento...</p>
                    </div>
                ) : unidades.length > 0 ? (
                    <ul className="list-group">
                        {unidades.map((unidad) => (
                            <li key={unidad.id} className="list-group-item">
                                <h5>{unidad.titulo}</h5>
                                <p>{unidad.descripcion}</p>
                                <Link to={`/lecciones/${unidad.id}`} className="btn btn-primary">Ir a las Lecciones</Link>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div>No se encontraron unidades para este curso.</div>
                )}
            </div>
        </div>
    );
    
}

export default Unidades;
