import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function InsertarUnidad() {
    

    let { id } = useParams(); // Extrae el ID del parámetro de la URL
    let navigate = useNavigate();

    const [unidad, setUnidad] = useState({
        curso_id: id,
        titulo: '',
        descripcion: '',
        activo: '1', // Por defecto, activamos la unidad
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUnidad({ ...unidad, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const response = await fetch('https://aprendizaje.chetito.shop/api/insertar_unidad.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(unidad)
            });

            const responseData = await response.json();
            if (responseData.success) {
                alert('Unidad insertada con éxito');
                // Opcional: Limpia el formulario o redirige al usuario
            } else {
                alert('Error al insertar la unidad');
            }
        } catch (error) {
            console.error('Error al insertar la unidad:', error);
            alert('Error al conectar con la API');
        }
        setUnidad({
        curso_id: id,
        titulo: '',
        descripcion: '',
        activo: '1', // Por defecto, activamos la unidad
    })
    };

    const handleBack = () => {
        navigate(-1); // Navega a la página anterior
    };


    return (
        <div className="container mt-3">
          
            <h2>Insertar Unidad del curso  {id}  </h2>
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Regresar</button>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Título</label>
                    <input type="text" className="form-control" name="titulo" value={unidad.titulo} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Descripción</label>
                    <textarea className="form-control" name="descripcion" value={unidad.descripcion} onChange={handleChange} required></textarea>
                </div>
                <div className="form-group form-check">
                    <input type="checkbox" className="form-check-input" name="activo" checked={unidad.activo === '1'} onChange={(e) => setUnidad({ ...unidad, activo: e.target.checked ? '1' : '0' })} />
                    <label className="form-check-label">Activo</label>
                </div>
                <button type="submit" className="btn btn-primary">Enviar</button>
            </form>
        </div>
    );
}

export default InsertarUnidad;
