// Aprender.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';



function Aprender() {
    let { id } = useParams(); // Extrae el ID del parámetro de la URL
    let navigate = useNavigate();
    const [info, setinfo] = useState([]);

      const handleBack = () => {
        navigate(-1); // Navega a la página anterior
    };


    const navigateToAprenderTarjetas = () => navigate(`/tarjetas/${id}`);
    const navigateToEvaluarTarjetas = () => navigate(`/tarjetas-evaluar/${id}`);
    
    useEffect(() => {
        const fetchData = async () => {
            try {

    
                const [infoResponse] = await Promise.all([
                    axios.get(`https://aprendizaje.chetito.shop/api/obtener_aprendijaze_one.php?id=${id}`)

                ]);
    
                if (infoResponse.data.success && infoResponse.data.cursos.length > 0) {
                    setinfo(infoResponse.data.cursos);
                }
    
            } catch (error) {
                console.error('Error:', error);

            } 
        };
    
        fetchData();
    }, [id]); // El efecto se ejecuta cuando cambia el valor de `id`

    return (
        <div>
            <div className="m-2">

            {Object.keys(info).map((key) => (
            <div key={key}>
            <h5>Lección de: {info[key].titulo}</h5>
            </div>
            ))}
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Regresar</button>
            <br></br>
            
            <hr></hr>

            <button onClick={navigateToAprenderTarjetas} className="btn btn-primary">Aprender con Tarjetas</button>
            <br />
            <br />
            <button onClick={navigateToEvaluarTarjetas} className="btn btn-primary">Evaluarme con Tarjetas</button>
           
            </div>

        </div>
    );
}

export default Aprender;
