
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './cargando.css';

function ListaCursos() {
    const [cursos, setCursos] = useState([]);
    const navigate = useNavigate();
    const [cargando, setCargando] = useState(true);


    useEffect(() => {
        const obtenerCursos = async () => {

            setCargando(true); // Inicia la carga

            try {
                const respuesta = await fetch('https://aprendizaje.chetito.shop/api/obtener_cursos.php');
                const datos = await respuesta.json();

                if (datos.success) {
                    setCursos(datos.cursos);
                } else {
                    alert('No se pudieron obtener los cursos');
                }
            } catch (error) {
                console.error('Error al obtener los cursos:', error);
                alert('Error al conectar con la API');
            }finally {
            setCargando(false); // Finaliza la carga
        }
        };

        obtenerCursos();
    }, []);

    // Función para manejar el clic en el botón "Ir"
    const irACurso = (id) => {
        navigate(`/unidades/${id}`);
    };

    return (
        <div>
            <h2>Lista de Aprendizajes</h2>

{cargando ? (
<div style={{ textAlign: 'center' }}> {/* Esto centra todo el contenido dentro del div */}
<img src="/cargando.png" className="cargando" alt="Cargando" style={{ display: 'block', margin: 'auto' }} />
<p>Procesando, espere un momento...</p> {/* Este texto estará centrado */}
</div>
) : (

            <table className="table">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Descripción</th>
                        <th>Fecha de Inicio</th>
                        <th>Fecha de Creación</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {cursos.map(curso => (
                        <tr key={curso.id}>
                            <td>{curso.nombre}</td>
                            <td>{curso.descripcion}</td>
                            <td>{curso.fecha_inicio}</td>
                            <td>{curso.fecha_creacion}</td>
                            <td>
                                <button onClick={() => irACurso(curso.id)} className="btn btn-primary">
                                    Ir
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )}
        </div>
    );
}

export default ListaCursos;
