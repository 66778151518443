import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function CursoForm() {
    const [curso, setCurso] = useState({
        nombre: '',
        descripcion: '',
        fecha_inicio: '',
        activo: 1,
    });

    let navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurso({ ...curso, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://aprendizaje.chetito.shop/api/insertar_curso.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(curso)
            });
    
            if (response.ok) {
                const responseData = await response.text(); // Primero obtén la respuesta como texto
                const data = responseData ? JSON.parse(responseData) : {}; // Luego intenta analizarlo como JSON
                console.log(data);
                alert('Curso insertado con éxito');

                        // Limpiar el formulario restableciendo el estado del curso a su valor inicial
        setCurso({
            nombre: '',
            descripcion: '',
            fecha_inicio: '',
            activo: 1,
        });
        
            } else {
                throw new Error('Respuesta de la API no fue OK.');
            }
        } catch (error) {
            console.error('Error al insertar el curso:', error);
            alert('Error al insertar el curso');
        }
    };
    
    const handleBack = () => {
        navigate(-1); // Navega a la página anterior
    };

    return (

        <div className="container mt-5">
        <h2>Insertar Curso</h2> 
        <button type="button" className="btn btn-secondary ml-2" onClick={handleBack}>Regresar</button>
        <form onSubmit={handleSubmit} className="container mt-5">
            <div className="form-group">
                <label htmlFor="nombre">Nombre del Curso</label>
                <input
                    type="text"
                    className="form-control"
                    id="nombre"
                    name="nombre"
                    value={curso.nombre}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="descripcion">Descripción</label>
                <textarea
                    className="form-control"
                    id="descripcion"
                    name="descripcion"
                    rows="3"
                    value={curso.descripcion}
                    onChange={handleChange}
                    required
                ></textarea>
            </div>
            <div className="form-group">
                <label htmlFor="fecha_inicio">Fecha de Inicio</label>
                <input
                    type="date"
                    className="form-control"
                    id="fecha_inicio"
                    name="fecha_inicio"
                    value={curso.fecha_inicio}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="form-check mb-3">
                <input
                    type="checkbox"
                    className="form-check-input"
                    id="activo"
                    name="activo"
                    checked={curso.activo === 1}
                    onChange={(e) => setCurso({ ...curso, activo: e.target.checked ? 1 : 0 })}
                />
                <label className="form-check-label" htmlFor="activo">Activo</label>
            </div>
            <button type="submit" className="btn btn-primary">Enviar</button>
        </form>
        </div>
    );
}

export default CursoForm;
